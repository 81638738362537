import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AppLoad } from '../app.load';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {

    constructor(public appGlobal: AppLoad, private httpClient:HttpClient) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let online = navigator.onLine;
        let token: "";
        let userSession = this.appGlobal.GetSession(this.appGlobal.appSession);
        if (userSession != null)
            token = userSession.loginDetails != null ? userSession.loginDetails.access_token:"";
        if (!(request.url.indexOf('/home/GetLanguageResources') > -1) && !(request.url.indexOf("account/uploadFile") > -1)) {            request = request.clone({
                setHeaders: {
                    "Content-Type": "application/json",
                    "Accept-Language": this.appGlobal.selectedLanguage,
                    "SiteID": this.appGlobal.appSettings.commonSettings.siteId1,
                    "SiteUrl": this.appGlobal.appSettings.commonSettings.siteUrl,
                    "installation_id": this.appGlobal.appSettings.commonSettings.installationId,
                    'Authorization': 'Bearer ' + token,
                    'pragma': 'no-cache',
                    "client_id": this.appGlobal.appSettings.commonSettings.clientId,
                }
            })
        }
        return next.handle(request).pipe(
            catchError((err:HttpErrorResponse) => {
            if (online){
                const loginSession = this.appGlobal.GetSession(this.appGlobal.appSession);
                if(loginSession != null && err.status === 401){
                    let httpHeaders = new HttpHeaders({
                        "Content-Type": "application/json",
                        "client_id": this.appGlobal.appSettings.commonSettings.clientId,
                        "installation_id": this.appGlobal.appSettings.commonSettings.installationId,
                        "Accept-Language": this.appGlobal.selectedLanguage,
                        "SiteID":this.appGlobal.appSettings.commonSettings.siteId1,
                        "SiteUrl":this.appGlobal.appSettings.commonSettings.siteUrl,
                        'pragma': 'no-cache'
                    });
                    let options = {
                        headers: httpHeaders
                    };
                    let body = JSON.stringify(loginSession.loginDetails.refresh_token);
                    return this.httpClient.post(this.appGlobal.appSettings.commonSettings.CfmhApiUrl+"account/token",body,options).pipe(
                        switchMap((res: any)=>{
                            let appSession = this.appGlobal.GetSession(this.appGlobal.appSession);
                            appSession.loginDetails = res;
                            if(!!res)
                            this.appGlobal.SetSession(appSession);
                            return next.handle(request.clone({
                                setHeaders:{
                                    'Authorization': 'Bearer ' + res.access_token
                                }
                            }))
                        })
                    )
                }
                else{
                    return throwError(err);
                }
            }
            else {
                var error = { error: { Errors: [{ Message: this.appGlobal.currentLanguageResources.ip360_home_offlineErrorMessage }] } }
                return throwError(error);
            }
        }
        ));
    }
}
